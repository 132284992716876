import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { AppDashboardComponent } from './component/app-dashboard/app-dashboard.component';
import { MyNavComponent } from './component/my-nav/my-nav.component';
import { AboutusComponent } from './component/mainContent/aboutus/aboutus.component';
import { ContactComponent } from './component/mainContent/aboutus/contact/contact.component';

import { PrescriptionComponent } from './component/mainContent/prescription/prescription.component';
import { PrescriptionResultsComponent } from './component/mainContent/prescription/prescription-results/prescription-results.component';

import { AppRoutingModule } from './/app-routing.module';
import { MatTabsModule } from '@angular/material/tabs';
import { HttpClientModule } from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import {HealthMaterialModule} from './material-module';
import { FormFieldComponent } from './form-field/form-field.component';
import {ShowErrorDirective} from './show-error-directive';
import { RegistrationFormComponent } from './component/registration-form/registration-form.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from './components/modal/modal.component';
import { GoogleSigninComponent } from './component/google-signin/google-signin.component';
import {
  SocialLoginModule,
  SocialAuthServiceConfig,
  GoogleLoginProvider,
} from '@abacritt/angularx-social-login';

@NgModule({
  declarations: [
    AppComponent,
    MyNavComponent,
    AppDashboardComponent,
    AboutusComponent,
    FormFieldComponent,
    ShowErrorDirective,
    PrescriptionComponent,
    PrescriptionResultsComponent,
    ContactComponent,
    RegistrationFormComponent,
    ModalComponent,
    GoogleSigninComponent
  ],
    imports: [
        BrowserModule,
        HttpClientModule,
        FormsModule,
        BrowserAnimationsModule,
        // ButtonModule,
        // GalleriaModule,
        LayoutModule,
        MatToolbarModule,
        MatButtonModule,
        MatSidenavModule,
        MatIconModule,
        MatListModule,
        MatGridListModule,
        MatCardModule,
        MatMenuModule,
        AppRoutingModule,
      // CardModule,
        MatTabsModule,
        NgxSpinnerModule,
        HealthMaterialModule,
        ReactiveFormsModule,
        NgbModule,
        SocialLoginModule
    ],
  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider('276544637944-4edaodmms81odc3i7vdlhlm9sra336l8.apps.googleusercontent.com')
          },
        ],
        onError: (err) => {
          console.error(err);
        },
      } as SocialAuthServiceConfig,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
