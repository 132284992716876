// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidenav-container {
  height: 100%;
}

.sidenav {
  width: 200px;
  box-shadow: 3px 0 6px rgba(0,0,0,.24);
}

/* .navbar-nav .dropdown-menu {
  position: absolute !important;
   float: none;
} */

.menubar-custom {
  background-color:#E6F68D!important;
}
`, "",{"version":3,"sources":["webpack://./src/app/component/my-nav/my-nav.component.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,qCAAqC;AACvC;;AAEA;;;GAGG;;AAEH;EACE,kCAAkC;AACpC","sourcesContent":[".sidenav-container {\n  height: 100%;\n}\n\n.sidenav {\n  width: 200px;\n  box-shadow: 3px 0 6px rgba(0,0,0,.24);\n}\n\n/* .navbar-nav .dropdown-menu {\n  position: absolute !important;\n   float: none;\n} */\n\n.menubar-custom {\n  background-color:#E6F68D!important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
