// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
  width: 100%;
  border:1px;
}

tr.prescription-detail-row {
  height: 0;
}

tr.prescription-element-row:not(.prescription-expanded-row):hover {
  background: whitesmoke;
}

tr.prescription-element-row:not(.prescription-expanded-row):active {
  background: #efefef;
}

.prescription-element-row td {
  border-bottom-width: 0;
}

.prescription-element-detail {
  overflow: hidden;
  display: flex;
}

.mat-row:nth-child(2n+1) {
  background-color: #e4f0ec;
}

.mat-row:not(:nth-child(4n+1)) {
  background-color: #ffffff;
}

.mat-header-cell {
  color: #5e75b1;
  font-size: medium;
}

.mat-cell {
  color: black;
  font-size: medium;

}
`, "",{"version":3,"sources":["webpack://./src/app/component/mainContent/prescription/prescription-results/prescription-results.component.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,UAAU;AACZ;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,iBAAiB;;AAEnB","sourcesContent":["table {\n  width: 100%;\n  border:1px;\n}\n\ntr.prescription-detail-row {\n  height: 0;\n}\n\ntr.prescription-element-row:not(.prescription-expanded-row):hover {\n  background: whitesmoke;\n}\n\ntr.prescription-element-row:not(.prescription-expanded-row):active {\n  background: #efefef;\n}\n\n.prescription-element-row td {\n  border-bottom-width: 0;\n}\n\n.prescription-element-detail {\n  overflow: hidden;\n  display: flex;\n}\n\n.mat-row:nth-child(2n+1) {\n  background-color: #e4f0ec;\n}\n\n.mat-row:not(:nth-child(4n+1)) {\n  background-color: #ffffff;\n}\n\n.mat-header-cell {\n  color: #5e75b1;\n  font-size: medium;\n}\n\n.mat-cell {\n  color: black;\n  font-size: medium;\n\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
