// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
}

.component-wrapper {
  margin-bottom: 16px;
}

.invalid-feedback {
  color: red;
  display: inherit;
}
`, "",{"version":3,"sources":["webpack://./src/app/form-field/form-field.component.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,UAAU;EACV,gBAAgB;AAClB","sourcesContent":[":host {\n  display: block;\n}\n\n.component-wrapper {\n  margin-bottom: 16px;\n}\n\n.invalid-feedback {\n  color: red;\n  display: inherit;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
