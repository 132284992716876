// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-backdrop{
  position:fixed;
  top:0;
  right:0;
  bottom:0;
  left:0;
  z-index:10;
  background-color:#000
}
`, "",{"version":3,"sources":["webpack://./src/app/component/mainContent/aboutus/aboutus.component.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,KAAK;EACL,OAAO;EACP,QAAQ;EACR,MAAM;EACN,UAAU;EACV;AACF","sourcesContent":[".modal-backdrop{\n  position:fixed;\n  top:0;\n  right:0;\n  bottom:0;\n  left:0;\n  z-index:10;\n  background-color:#000\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
