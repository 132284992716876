// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jumbotron{
    padding: 1rem 1rem !important;
    margin-bottom: 0px !important;
}

header{
    top: 0;
    z-index: 10;
    position: sticky;
}

body {
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.background-img {
 background-image: url("/assets/img/img.png.png");
  height: 250px;
}

.modal-backdrop{
  position:fixed;
  top:0;
  right:0;
  bottom:0;
  left:0;
  z-index:50;
  background-color:#000
}

.tf-backdrop-class{
  z-index: 55 !important;
}
`, "",{"version":3,"sources":["webpack://./src/app/app.component.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,6BAA6B;AACjC;;AAEA;IACI,MAAM;IACN,WAAW;IAEX,gBAAgB;AACpB;;AAEA;EACE,+BAA+B;EAC/B,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;CACC,gDAAgD;EAC/C,aAAa;AACf;;AAEA;EACE,cAAc;EACd,KAAK;EACL,OAAO;EACP,QAAQ;EACR,MAAM;EACN,UAAU;EACV;AACF;;AAEA;EACE,sBAAsB;AACxB","sourcesContent":[".jumbotron{\n    padding: 1rem 1rem !important;\n    margin-bottom: 0px !important;\n}\n\nheader{\n    top: 0;\n    z-index: 10;\n    position: -webkit-sticky;\n    position: sticky;\n}\n\nbody {\n  font-family: var(--font-family);\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\n.background-img {\n background-image: url(\"/assets/img/img.png.png\");\n  height: 250px;\n}\n\n.modal-backdrop{\n  position:fixed;\n  top:0;\n  right:0;\n  bottom:0;\n  left:0;\n  z-index:50;\n  background-color:#000\n}\n\n.tf-backdrop-class{\n  z-index: 55 !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
