import {AfterViewInit, Component, TemplateRef, OnInit, ViewChild, OnDestroy} from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import {clientCaption, clientName} from './constant/applicationDetails.constant';
import {ModalComponent} from './components/modal/modal.component';
import {ModalConfig} from './components/modal/modal.config';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import { SocialAuthService } from '@abacritt/angularx-social-login';
import { Subscription } from 'rxjs';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy{
  authSubscription!: Subscription;

  images: any[];
  title = 'app';
  readonly  clientName = clientName;
  readonly clientCaption = clientCaption;
  name: any;
  email: any;
  student_year: any;
  id_card_num: any;
  institution: any;
  contact_cat: any;


  ngOnDestroy(): void {
    this.authSubscription.unsubscribe();
  }

  ngOnInit() {
    this.authSubscription = this.authService.authState.subscribe((user) => {
      console.log('user', user);
    });
  }

  registrationForm = new FormGroup({
    'name': new FormControl('', Validators.required),
    'email': new FormControl('', Validators.required),
    'phone': new FormControl('', Validators.required),
    'id_card_num': new FormControl('', Validators.required)
  });

  @ViewChild('modal') private modalComponent: ModalComponent;

  public modalConfig: ModalConfig = {
    modalTitle: 'Registration',
    onDismiss: () => {
      return true;
    },
    hideDismissButton(): boolean {
      return true;
    },
    hideCloseButton(): boolean {
      return true;
    },
    dismissButtonLabel: 'Dismiss',
    onClose: () => {
      return true;
    },
    closeButtonLabel: 'Close'
  };

  constructor(private spinner: NgxSpinnerService, private authService: SocialAuthService) {
    this.images = [];
  }

  showSpinner(): void {
    this.spinner.show();
    setTimeout(() => this.spinner.hide(), 5000);
  }

  async openModal() {
    return await this.modalComponent.open();
  }

  submitRegistration(): void {

  }

  googleSignin(googleWrapper: any) {
    googleWrapper.click();
  }

}
