import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ROOT_URL } from '../../../constant/app.constant';
import { ReferenceDataService } from '../../../referencedata.service';

import { NgxSpinnerService } from 'ngx-spinner';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-prescription',
  templateUrl: './prescription.component.html',
  styleUrls: ['./prescription.component.css']
})
export class PrescriptionComponent implements OnInit {

  patientForm = new FormGroup({
    'ageGroup': new FormControl('', Validators.required),
    'gender': new FormControl('', Validators.required),
    'sleep': new FormControl('', Validators.required),
    'bmi': new FormControl('', Validators.required),
    'bowelmovement': new FormControl('', Validators.required),
    'diagnosis': new FormControl('', Validators.required),
    'dosha': new FormControl('', Validators.required),
    'appetite': new FormControl('', Validators.required),
  });

  constructor(private httpClient: HttpClient, private ngxSpinner: NgxSpinnerService, private referenceService: ReferenceDataService) { }
  diagnosis: any;
  gender: any;
  age: any;
  height: any;
  weight: any;
  sleep: any;
  appetite: any;
  bmi: any;
  bowelmovement: any;
  dosha: any;

  prescriptionResultList: any;

  // Referene data
  appetiteList:any;
  bowelMovementList:any;
  symptomsList:any;
  ageCategoryList:any;
  bmiCategoryList:any;
  genderList:any;
  doshaCategoryList:any;
  sleepList:any;


  @Output('command') command: EventEmitter<string> = new EventEmitter<string>();

  ngOnInit() {
    this.fetchReferenceData('APPETITE');
    this.fetchReferenceData('BOWEL_MVMT');
    this.fetchReferenceData('SYMPTOMS_CATEGORY');
    this.fetchReferenceData('BMI_CATEGORY');
    this.fetchReferenceData('AGE_CATEGORY');
    this.fetchReferenceData('GENDER');
    this.fetchReferenceData('DOSHA_CATEGORY');
    this.fetchReferenceData('SLEEP');
  }

  searchPrescriptions(): void {

    const requestPayload = {symptoms: this.diagnosis, gender: this.gender, age: this.age, bmi: this.bmi, dosha: this.dosha,
      height: this.height, weight: this.weight, sleep: this.sleep, appetite: this.appetite, bowelmovement:this.bowelmovement} ;

    const headers = new HttpHeaders();

    this.ngxSpinner.show();

    //TODO code has to be moved into service layer...

    this.httpClient.post(ROOT_URL + '/prescription-search', requestPayload)
      .subscribe(response => {
        this.prescriptionResultList=JSON.parse(JSON.stringify(response));
        this.ngxSpinner.hide();
      }, error => {
        alert('Error occured while searching prescription data !');
        this.ngxSpinner.hide();
      });
  }

  fetchReferenceData(referenceType:string): void {
    this.ngxSpinner.show();
    this.referenceService.getReferenceData(referenceType)
      .subscribe((response) => {
        if(referenceType === 'APPETITE') {
          this.appetiteList=JSON.parse(JSON.stringify(response));
        } else if(referenceType === 'BOWEL_MVMT') {
          this.bowelMovementList=JSON.parse(JSON.stringify(response));
        } else if(referenceType === 'SYMPTOMS_CATEGORY') {
          this.symptomsList=JSON.parse(JSON.stringify(response));
        } else if(referenceType === 'AGE_CATEGORY') {
          this.ageCategoryList=JSON.parse(JSON.stringify(response));
        } else if(referenceType === 'GENDER') {
          this.genderList = JSON.parse(JSON.stringify(response));
        } else if (referenceType === 'BMI_CATEGORY') {
          this.bmiCategoryList = JSON.parse(JSON.stringify(response));
        } else if(referenceType === 'DOSHA_CATEGORY') {
          this.doshaCategoryList=JSON.parse(JSON.stringify(response));
        }  else if(referenceType === 'SLEEP') {
          this.sleepList=JSON.parse(JSON.stringify(response));
        }
        this.ngxSpinner.hide();
      },
        error => { console.log(error); this.ngxSpinner.hide();},
        () => { }
      );
  }

}
