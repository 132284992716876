import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ROOT_URL} from '../../../../constant/app.constant';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  contactForm = new FormGroup({
    'email': new FormControl('', Validators.required),
    'message': new FormControl('', Validators.required),
    'name': new FormControl('', Validators.required),
    'phone': new FormControl('', [Validators.required,  Validators.pattern("^(\\+91[\\-\\s]?)?[0]?(91)?[789]\\d{9}$")])
  });
  constructor(private httpClient: HttpClient, private ngxSpinner: NgxSpinnerService) { }
  name: string;
  email: string;
  phone: string;
  message: string;
  feedbackResponse: any;
  statusMessage: string;
  ngOnInit() {

  }

  submitFeedback(): void {

    const requestPayload = {contactName: this.name, contactEmail: this.email.trim(), mobileNum: this.phone.trim(), feedbackTxt: this.message, contactCat: 'FEEDBACK'} ;

    const headers = new HttpHeaders();

    this.ngxSpinner.show();

    //TODO code has to be moved into service layer...

    this.httpClient.post(ROOT_URL + '/registration', requestPayload)
      .subscribe(response => {
        this.feedbackResponse=JSON.parse(JSON.stringify(response));
        this.statusMessage = "Thank you for submitting the feedback.";
        this.ngxSpinner.hide();
      }, error => {
        alert('Error occured while searching prescription data !');
        this.ngxSpinner.hide();
      });
  }


}
