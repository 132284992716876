import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { menuItems } from '../../constant/menu.constant';
import { clientName } from '../../constant/applicationDetails.constant';
import {NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'my-nav',
  templateUrl: './my-nav.component.html',
  styleUrls: ['./my-nav.component.css']
})
export class MyNavComponent {

  menuList = [];
  readonly applicationName = clientName;

  constructor(private breakpointObserver: BreakpointObserver) {
    this.menuList = menuItems;
  }

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );



}
