import { Component, OnInit, Input } from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';


@Component({
  selector: 'app-prescription-results',
  templateUrl: './prescription-results.component.html',
  styleUrls: ['./prescription-results.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class PrescriptionResultsComponent implements OnInit {

  @Input('prescriptionResultList') prescriptionResultList: any;
  activePrescription: any;
  columnsToDisplay = ['prescription','dosage','unit','prescriptionScore'];

  ngOnInit() {

  }

}
