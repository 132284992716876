import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ModalComponent} from '../../components/modal/modal.component';
import {ModalConfig} from '../../components/modal/modal.config';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-registration-form',
  templateUrl: './registration-form.component.html',
  styleUrls: ['./registration-form.component.css']
})
export class RegistrationFormComponent {
  constructor() { }

  registrationForm = new FormGroup({
    'name': new FormControl('', Validators.required),
    'email': new FormControl('', Validators.required),
    'phone': new FormControl('', Validators.required),
    'id_card_num': new FormControl('', Validators.required)
  });
  name: any;
  email: any;
  student_year: any;
  id_card_num: any;
  institution: any;
  contact_cat: any;

  @ViewChild('modal') private modalComponent: ModalComponent;

  public modalConfig: ModalConfig = {
    modalTitle: 'Registration',
    onDismiss: () => {
      return true;
    },
    dismissButtonLabel: 'Dismiss',
    onClose: () => {
      return true;
    },
    closeButtonLabel: 'Close'
  };


  submitRegistration(): void {

  }

  public async openModal() {
    console.log(this.modalComponent);
    // return await this.modalComponent.open();
  }
}
