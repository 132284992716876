import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AppDashboardComponent } from './component/app-dashboard/app-dashboard.component';
import { AboutusComponent } from './component/mainContent/aboutus/aboutus.component';
import { ContactComponent } from './component/mainContent/aboutus/contact/contact.component';

import { PrescriptionComponent } from './component/mainContent/prescription/prescription.component';


const routes: Routes = [
  { path: '', component: AppDashboardComponent },
  { path: 'aboutus', component: AboutusComponent },
  { path: 'prescription', component: PrescriptionComponent },
  { path: 'feedback', component: ContactComponent }

];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, { enableTracing: false, useHash: true })
  ],
  declarations: [],
  exports: [RouterModule]
})
export class AppRoutingModule {


}
